import React from 'react'
import PageWrapper from '../PageWrapper/PageWrapper'
import teachers from './TeachersConfig'
import LogoWrappedImage from '../LogoWrappedImage/LogoWrappedImage'
import './TeachersPage.css'

const TeachersPage = () => {
  return (
    <PageWrapper title='Teachers'>
      <div className='teachers-large-screen'>
        <div className='teacher-chelsea'>
          <LogoWrappedImage image={teachers[0].image} altText={teachers[0].name} />
          <div className='teacher-text'>
            <div className='teacher-name'>{teachers[0].name}</div>
            <div className='teacher-position'>{teachers[0].position}</div>
          </div>
        </div>
        <div className='teachers-grid'>
          {teachers.slice(1).map(teacher => 
            <div key={teacher.name}>
              <div className='teacher-text'>
                <div className='teacher-name'>{teacher.name}</div>
                <div className='teacher-position'>{teacher.position}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='teachers-small-screen'>
        {teachers.map(teacher => 
          <div key={teacher.name}>
            <div className='teacher-text'>
              <div className='teacher-name'>{teacher.name}</div>
              <div className='teacher-position'>{teacher.position}</div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  )
}

export default TeachersPage