export const timetable = [
  // Row 1
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: '',
      Time: ''
    },
    Wednesday: {
      Class: 'Tiny Tots (18mths - 3yrs)',
      Time: '9.30 - 10.15am'
    },
    Thursday: {
      Class: '',
      Time: ''
    },
    Friday: {
      Class: '',
      Time: ''
    },
  },
  // Row 2
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: '',
      Time: ''
    },
    Wednesday: {
      Class: 'Lil Groovers (3-5yrs)',
      Time: '10.15 - 11am'
    },
    Thursday: {
      Class: '',
      Time: ''
    },
    Friday: {
      Class: '',
      Time: ''
    },
  },
  // Row 3
  {
    Monday: {
      Class: 'Pre/Kindy (4-6yrs) - Jazz & Contemp.',
      Time: '3.45 - 4.30pm'
    },
    Tuesday: {
      Class: 'Musical Theatre - Open Age',
      Time: '3.45 - 4.30pm'
    },
    Wednesday: {
      Class: 'Yr 1/2 Jazz',
      Time: '3.45 - 4.30pm'
    },
    Thursday: {
      Class: 'CC Stars Troupe',
      Time: '3.30 - 4.30pm'
    },
    Friday: {
      Class: 'Tap 1',
      Time: '3.45 - 4.30pm'
    },
  },
  // Row 4
  {
    Monday: {
      Class: 'Yr 3/4 Jazz',
      Time: '4.30 - 5.15pm'
    },
    Tuesday: {
      Class: 'Acro Dance - Open Age',
      Time: '4.30 - 5.15pm'
    },
    Wednesday: {
      Class: 'Yr 1/2 Contemporary',
      Time: '4.30 - 5.15pm'
    },
    Thursday: {
      Class: 'CC Senior Troupe',
      Time: '4 - 5pm'
    },
    Friday: {
      Class: 'Tap 2',
      Time: '4.30 - 5.15pm'
    },
  },
  // Row 5
  {
    Monday: {
      Class: 'Yr 3/4 Contemp.',
      Time: '5.15 - 6pm'
    },
    Tuesday: {
      Class: 'Hip Hop - Open Age',
      Time: '5.15 - 6pm'
    },
    Wednesday: {
      Class: 'Yr 5/6 Jazz',
      Time: '5.15 - 6pm'
    },
    Thursday: {
      Class: 'Yr 9+ Jazz & Contemp.',
      Time: '5 - 6pm'
    },
    Friday: {
      Class: 'Private Lessons',
      Time: '5.15pm'
    }
  },
  // Row 6
  {
    Monday: {
      Class: 'Ballet',
      Time: '6 - 6.45pm'
    },
    Tuesday: {
      Class: 'Yr 7/8 Jazz',
      Time: '6 - 6.45pm'
    },
    Wednesday: {
      Class: 'Yr 5/6 Contemporary',
      Time: '6 - 6.45pm'
    },
    Thursday: {
      Class: 'Private Lessons',
      Time: '6 - 7.30pm'
    },
    Friday: {
      Class: '',
      Time: ''
    }
  },
  // Row 7
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: 'Yr 7/8 Contemporary',
      Time: '6.45 - 7.30pm'
    },
    Wednesday: {
      Class: 'Private Lessons',
      Time: '6.45 - 7.45pm'
    },
    Thursday: {
      Class: '',
      Time: ''
    },
    Friday: {
      Class: '',
      Time: ''
    }
  }
]

export const ageGroups = [
  'Tiny Tots: 18mth-3yrs',
  'Lil Groovers: 3-5yrs',
  'Junior: K-Y1',
  'Pre-Intermediates: Y2-Y3',
  'Intermediates: Y4-Y5',
  'Pre-Senior: Y6-Y7',
  'Senior: Y8+',
  'Tap & Ballet are based on age & ability combined',
  'Hip Hop, Musical Theatre & Acro are open-age classes'
]