import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Icons from '../Icons/Icons'
import ccsymbol from '../../images/symbol-black.png'
import MobileNavDrawer from './MobileNavDrawer'
import menu from './NavConfig'
import './NavigationBar.css'

const NavigationBar = () => {
  const navigate = useNavigate()

  const onLogoClick = () => navigate('/')

  return (
    <div className='nav-bar'>
      <div onClick={onLogoClick} className='cc-symbol-wrapper'>
        <img src={ccsymbol} alt='CC Choreography'/>
      </div>
      <div className='nav-links-wrapper'>
        {menu.map(menuItem =>
          <NavLink 
            key={menuItem} 
            className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'} 
            end
            to={menuItem === 'Home' ? '/' 
              : menuItem === 'Enrol' ? 'https://pvmconsulting.formstack.com/forms/cccregistrationform'
              : `/${menuItem.toLowerCase()}`
            }
          >
            {menuItem}
          </NavLink>
        )}
      </div>
      
      <Icons instagram facebook />

      <MobileNavDrawer />
    </div>
  )
}

export default NavigationBar