import React from 'react'
import './Button.css'

const Button = props => {
  return (
    <a 
      href={props.href} 
      download={props.download} 
      target={props.target || '_self'}
      className='button'
    >
      <div>{props.text}</div>
    </a>
  )
}

export default Button