import chelsea from '../../images/chelsea.jpg'

const teachers = [
  {
    name: 'Chelsea Cole',
    position: 'Director, Teacher & Choreographer ',
    image: chelsea,
    about: 
    'Miss Chelsea has over 20 years dance experience and 10 years teaching experience. '
    + 'She teaches students from 2.5 years young, up to adults! Chelsea opened her Black Swan Dance Studio in 2015 '
    + '(re-named to CC Choreography in 2020). Her energy and passion for her work is evident to everyone who watches her teach and perform.'
    + '<br><br>Chelsea has experience teaching Jazz, Tap, Contemporary, Lyrical, Hip Hop, Acrobatics, Tiny Tots and Adults Dance Fitness. '
    + 'She is always pursuing new dance knowledge and styles and has also learnt Brazilian Zouk Partner Dancing, Salsa and Bachata styles.'
    + '<br><br>Over the years, she has choreographed and mentored students to winning countless solo, duo and troupe performance pieces '
    + 'at local eisteddfods around Newcastle.'
  },
  {
    name: 'Charlotte Connors',
    position: 'Teacher & Choreographer',
  },
  {
    name: 'Kaleisha Brown',
    position: 'Teacher & Choreographer',
  },
  {
    name: 'Grace Adams',
    position: 'Teacher & Choreographer',
  }
]

export default teachers