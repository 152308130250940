export const fees1 = [
  {class: '1 x 45min Class', price: '$16'},
  {class: '1 x 60min Class', price: '$20'},
  {class: 'Solo Private', price: '$36 / 30 minutes'},
  {class: 'Duo/Trio Private', price: '$22 / 30 minutes p.p.'},
]

export const fees2 = [
  {class: 'Annual Registration', price: '$50'},
  {class: 'Registration for Tiny Tots & Lil Groovers', price: '$30'},
]